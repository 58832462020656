<template>
    <section id="geolocalisation">
        <div class="container">
            <div class="title-effect position-relative">
                <h1 class="text-center title-text w-100 py-2">GEOLOCALISATION DES PRATICIENS DU TERRITOIRE</h1>
                <div class="background-text text-center">Geolocalisation</div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                   <l-map style="height: 50rem" :zoom="zoom" :center="center">
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <template v-for="(item, index) in praticien" >
                        <l-marker :lat-lng="item.markerLatLng" :key="index">
                            <l-tooltip>{{item.data.name}}</l-tooltip>
                        </l-marker>
                    </template>
                    </l-map>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

// import {LMap, LTileLayer, LMarker, LTooltip} from 'vue2-leaflet';

export default {
  name:"geolocalisation",
  components: {
    // LMap,
    // LTileLayer,
    // LMarker,
    // LTooltip
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 8,
      center: [45.313220, -1.319482],
      praticien :[
        {"markerLatLng": [45.316420, 1.369482],
         "data": {
            'name':'Docteur Jean Renaud',
            'phone':'56454664'
         }
        },
        {"markerLatLng": [45.316420, -1.359482],
         "data": {
            'name':'Pharmacie de garde XXX',
            'phone':'56454664'
         }
        },
        {"markerLatLng": [45.316420, 0.399482],
         "data": {
            'name':'Pompier',
            'phone':'56454664'
         }
        }
      ]
      
    };
  }
}
</script>
